import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay } from 'swiper';

// Components
import TestimonialCard from '../../components/card/Testimonial';

const TestimonialsSection = ({ site, city, sectionStyle }) => (
  <StaticQuery
    query={graphql`
      query {
        testimonials: allMdx(
          filter: {
            fileAbsolutePath: { regex: "/data/testimonials/" },
            frontmatter: { home: { eq: true }, active: { eq: true } }
          },
          sort: {fields: frontmatter___date, order: DESC}
        )
        {
          nodes {
            frontmatter {
              name
              rating
              website
              link
              home
              active
              date(formatString: "D MMMM YYYY", locale: "fr")
            }
            id
            slug
            body
          }
        }
      }
    `}
    render={data => (
      <>
        {sectionStyle === "1" &&
          <div id="testimnonials" className="wpo-testimonial-area section-padding">
            <div className="container">
              <div className="row">
                <div className="wpo-section-title">
                  <span>Témoignages</span>
                  <h2>Ce que nos clients pensent de nos services</h2>
                </div>
              </div>
              <div className="wpo-testimonial-wrap">
                <Swiper
                  className="testimonial-slider swiper"
                  modules={[A11y, Autoplay]}
                  autoplay={{ delay: 5000, disableOnInteraction: true }}
                  scrollbar={{ draggable: true }}
                  data-aos="fade-up" data-aos-delay="0" data-aos-offset="-50"
                  breakpoints={{
                    "380": {
                      "slidesPerView": 1,
                      "spaceBetween": 5,
                    },
                    "580": {
                      "slidesPerView": 2,
                      "spaceBetween": 10,
                    },
                    "768": {
                      "slidesPerView": 2,
                      "spaceBetween": 10,
                    },
                    "1024": {
                      "slidesPerView": 2,
                      "spaceBetween": 30,
                    }
                  }}
                >
                  {data.testimonials.nodes.map((testimonial, index) => (
                    <SwiperSlide key={testimonial.id} className="swiper-slide">
                      <TestimonialCard key={testimonial.id} data={testimonial} cardStyle="1" />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        }
        {sectionStyle === "2" &&
          <div id="testimnonials" className="wpo-testimonial-area section-padding">
            <div className="container">
              <div className="row">
                <div className="wpo-section-title">
                  <span>Témoignages</span>
                  <h2>Ce que nos clients pensent de nos services à {city.name}</h2>
                </div>
              </div>
              <div className="wpo-testimonial-wrap">
                <Swiper
                  className="testimonial-slider swiper"
                  modules={[A11y, Autoplay]}
                  autoplay={{ delay: 5000, disableOnInteraction: true }}
                  scrollbar={{ draggable: true }}
                  data-aos="fade-up" data-aos-delay="0" data-aos-offset="-50"
                  breakpoints={{
                    "380": {
                      "slidesPerView": 1,
                      "spaceBetween": 5,
                    },
                    "580": {
                      "slidesPerView": 2,
                      "spaceBetween": 10,
                    },
                    "768": {
                      "slidesPerView": 2,
                      "spaceBetween": 10,
                    },
                    "1024": {
                      "slidesPerView": 2,
                      "spaceBetween": 30,
                    }
                  }}
                >
                  {data.testimonials.nodes.map((testimonial, index) => (
                    <SwiperSlide key={testimonial.id} className="swiper-slide">
                      <TestimonialCard key={testimonial.id} data={testimonial} cardStyle="1" />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        }
      </>
    )}
  />
)

export default TestimonialsSection