import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay } from 'swiper';

// Components
import MemberCard from '../../components/card/Member';

const TeamSection = ({ site, city, sectionStyle }) => (
  <StaticQuery
    query={graphql`
      query {
        members: allMdx(
          filter: {
            fileAbsolutePath: { regex: "/data/members/" },
            frontmatter: { home: { eq: true }, active: { eq: true } }
          },
          sort: {fields: frontmatter___date, order: DESC}
        )
        {
          nodes {
            frontmatter {
              name
              description
              position
              website
              email
              telephone
              social_networks {
                linkedIn
                facebook
                instagram
                twitter
              }
              image {
                card {
                  childImageSharp {
                    gatsbyImageData
                    fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                alt
                credit {
                  text
                  link
                }
              }
              home
              active
              date(formatString: "D MMMM YYYY", locale: "fr")
            }
            id
            slug
            body
          }
        }
      }
    `}
    render={data => (
      <>
        {sectionStyle === "1" &&
          <section id="team" className="wpo-team-section section-padding">
            <div className="container">
              <div className="row">
                <div className="wpo-section-title">
                  <span>Nos experts</span>
                  <h2>Rencontrez notre équipe</h2>
                </div>
              </div>
              <div className="wpo-team-wrap">
                <div className="row">
                  <StaticImage src="../../images/team/equipe-mousse-gava.png" alt={`Équipe de ${site.siteMetadata.title} à Roanne`} />
                </div>
              </div>
            </div>
          </section>
        }
        {sectionStyle === "2" &&
          <section id="team" className="wpo-team-section section-padding">
            <div className="container">
              <div className="row">
                <div className="wpo-section-title">
                  <span>Nos experts</span>
                  <h2>Rencontrez notre équipe</h2>
                </div>
              </div>
              <div className="wpo-team-wrap">
                  <Swiper
                    className="team-slider swiper"
                    modules={[A11y, Autoplay]}
                    autoplay={{ delay: 5000, disableOnInteraction: true }}
                    scrollbar={{ draggable: true }}
                    breakpoints={{
                      "380": {
                        "slidesPerView": 1,
                        "spaceBetween": 5,
                      },
                      "580": {
                        "slidesPerView": 2,
                        "spaceBetween": 10,
                      },
                      "768": {
                        "slidesPerView": 3,
                        "spaceBetween": 20,
                      },
                      "1024": {
                        "slidesPerView": 4,
                        "spaceBetween": 25,
                      }
                    }}
                  >
                    {data.members.nodes.map((member, index) => (
                      <SwiperSlide key={member.id} className="swiper-slide">
                        <MemberCard key={member.id} data={member} cardStyle="1" />
                      </SwiperSlide>   
                    ))
                  }
                </Swiper>
              </div>
            </div>
          </section>
        }
        {sectionStyle === "3" &&
          <section id="team" className="wpo-team-section section-padding">
            <div className="container">
              <div className="row">
                <div className="wpo-section-title">
                  <span>Rencontrez notre équipe</span>
                  <h2>À votre service à {city.name}</h2>
                </div>
              </div>
              <div className="wpo-team-wrap">
                <div className="row">
                  <StaticImage src="../../images/team/equipe-mousse-gava.png" alt={`Équipe de ${site.siteMetadata.title} à ${city.name} (${city.postal_code})`} />
                </div>
              </div>
            </div>
          </section>
        }
      </>
    )}
  />
)

export default TeamSection