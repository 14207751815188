import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../components/layout/Layout';
import TeamSection from '../components/section/Team';
import TestimonialsSection from '../components/section/Testimonials';
import ContactSection from '../components/section/Contact';
//import MemberCard from '../components/card/Member';

const TeamPage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
          socialNetworks {
            facebook
            instagram
            linkedIn
          }
          author {
            name
            url
            creator
            image {
              url
            }
          }
          publisher {
            name
            url
            logo {
              url
            }
          }
        }
      }
      headerBackgroundImage: file(relativePath: { eq: "headers/equipe-mousse-gava.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      members: allMdx(
        filter: {
          fileAbsolutePath: { regex: "/data/members/" },
          frontmatter: { home: { eq: true }, active: { eq: true } }
        },
        sort: {fields: frontmatter___date, order: DESC}
      )
      {
        nodes {
          frontmatter {
            name
            description
            position
            website
            email
            telephone
            social_networks {
              linkedIn
              facebook
              instagram
              twitter
            }
            image {
              card {
                childImageSharp {
                  gatsbyImageData
                  fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
              credit {
                text
                link
              }
            }
            home
            active
            date(formatString: "D MMMM YYYY", locale: "fr")
          }
          id
          slug
          body
        }
        totalCount
      }
    }
  `)

  // Set ImageData.
  const headerBackgroundImage = query.headerBackgroundImage.childImageSharp.fluid
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.1))`,
  ].reverse()

  return (
    <Layout 
      pageTitle={`L'équipe | ${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, carrelage, faïence, sols souples, façade et isolation intérieure à Roanne`}
      pageDescription={`L'équipe expérimentée et dynamique de ${query.site.siteMetadata.title} à votre service depuis 70 ans. Découvrez ce que nos clients disent de nous.`}
      pageKeywords="moussé gava, équipe, témoignages, avis, roanne, riorges, mably, qualité, travaux, plâtrerie, peinture"
      headerClassName="wpo-site-header wpo-header-style-2"
    >
      <BackgroundImage
        Tag="section"
        className="wpo-page-title"
        fluid={headerBackgroundImageStack}
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <h2>L'équipe</h2>
                <ol className="wpo-breadcumb-wrap">
                  <li><Link to={`/`}>Accueil</Link></li>
                  <li>Équipe</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <TeamSection site={query.site} sectionStyle="1" />

      {/*
      {query.members && query.members.nodes.length ?
        <div className="wpo-team-area section-padding">
          <div className="container">
            <div className="row align-items-center">
              {query.members.nodes.map(member => (
                <MemberCard key={member.id} data={member} cardStyle="2" cardClassName="col-lg-3 col-md-4 col-sm-6 col-12" />
              ))}
            </div>
          </div>
        </div>
      : null}
      */}
      <TestimonialsSection site={query.site} sectionStyle="1" />
      <ContactSection site={query.site} sectionStyle="1" />
    </Layout>
  )
}

export default TeamPage
