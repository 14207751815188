import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const MemberCard = ({ data, cardStyle, cardClassName }) => {
  return (
    <>
      {cardStyle === "1" &&
        <div key={data.id} className={cardClassName}>
          <div className="wpo-team-item">
            <GatsbyImage
              className="wpo-team-img"
              image={getImage(data.frontmatter.image.card)}
              alt={data.frontmatter.image.alt}
            />
            <div className="wpo-team-text">
              {data.frontmatter.name &&
                <h3>{data.frontmatter.name}</h3>
              } 
              {data.frontmatter.position &&
                <span>{data.frontmatter.position}</span>
              } 
            </div>
          </div>
        </div>
      }
      {cardStyle === "2" &&
        <div key={data.id} className={cardClassName} data-aos="fade-up" data-aos-delay="0" data-aos-offset="-50">
          <div className="wpo-team-item">
            <GatsbyImage
              className="wpo-team-img"
              image={getImage(data.frontmatter.image.card)}
              alt={data.frontmatter.image.alt}
            />
            <div className="wpo-team-text">
              {data.frontmatter.name &&
                <h3>{data.frontmatter.name}</h3>
              } 
              {data.frontmatter.position &&
                <span>{data.frontmatter.position}</span>
              } 
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default MemberCard